import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { FaInstagram } from 'react-icons/fa'
import { DOMParser } from 'xmldom'
import { DiscussionEmbed } from 'disqus-react'

import Layout from '../components/Layout'
import styles from '../templateStyles/people.module.css'
import YouMayAlso from '../components/PostTemplateComponents/youMayAlso'
import SEO from '../components/SEO/SEO'
import Emojis from '../components/Emojis/emojis'

export const BlogPostTemplate = props => {
  return (
    <section className={styles.container}>
      <div className={styles.about}>
        <div className={styles.aboutLeft}>This is a featured Lengjai</div>
        <div className={styles.aboutRight}>Featured Lengjais represent our ideals</div>
      </div>
      <div className={styles.page}>
        <div className={styles.header}>
          <Img
            fluid={props.featuredMedia.localFile.childImageSharp.fluid}
            alt={''}
            className={styles.featuredImage}
          />
        </div>
        <div className={styles.content}>
          <div className={styles.subHeader}>
            <div className={styles.name}>{props.person}</div>
            <a
              className={styles.instaContainer}
              href={'https://instagram.com/' + props.instagram}
              target="_blank"
              aria-label="instagram"
              rel="noopener noreferrer"
            >
              <FaInstagram size={40} />
              <div className={styles.instaAt}>@{props.instagram}</div>
            </a>
          </div>
          <div
            className={styles.textBody}
            dangerouslySetInnerHTML={{ __html: props.content }}
          />
          <div className={styles.emojis}>
            <Emojis id={props.id} />
          </div>
          <div className={styles.discussion}>
            <DiscussionEmbed
              shortname="lengjai-com"
              config={props.disqusConfig}
            />
          </div>
          
        </div>
      </div>
      <div className={styles.youMayAlso}>
            <YouMayAlso data={props.relatedData} id={props.id} viewData={props.PageViews} />
          </div>
    </section>
  )
}

const people = ({ data }) => {
  const { wordpressPost: post } = data
  const { swag: relatedPostData } = data
  const { PageViews: PageViews } = data

  var dom = new DOMParser().parseFromString(`<div>${post.title}</div>`)
  var decodedString = dom.childNodes[0].textContent
  const disqusConfig = {
    url: `https://www.lengjai.com/articles/${post.slug}/`,
    identifier: `${post.id}`,
    title: `${post.title}`,
  }

  return (
    <Layout>
      <SEO
        title={post.title}
        description={post.excerpt || ''}
        image={post.featured_media.localFile.childImageSharp.fluid.src}
        pathname={post.slug}
        article
      />
      <BlogPostTemplate
        featuredMedia={post.featured_media}
        content={post.content}
        id={post.id}
        title={decodedString}
        date={post.date}
        relatedData={relatedPostData}
        disqusConfig={disqusConfig}
        //Lengjai query data
        person={post.acf.person}
        instagram={post.acf.instagram}
        PageViews={PageViews}
      />
    </Layout>
  )
}

people.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default people

export const pageQuery = graphql`
  query PeoplePostByID($id: String!, $categories: String!) {
    PageViews: allPageViews {
      edges {
        node {
          id
          totalCount
        }
      }
    }
    wordpressPost: wordpressPost(id: { eq: $id }) {
      id
      acf {
        excerpt
        instagram
        person
      }
      title
      author {
        name
      }
      slug
      content
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1000, maxHeight: 1000, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      date(formatString: "MMMM DD, YYYY")
      categories {
        name
        slug
      }
      tags {
        name
        slug
      }
    }
    swag: allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: $categories } } } }
    ) {
      edges {
        node {
          id
          title
          content
          acf {
            excerpt
          }
          slug
          date(formatString: "MMM D, YYYY")
          datetime: date
          categories {
            name
            id
          }
          author {
            name
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, maxHeight: 700, cropFocus: CENTER) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
